import {graphql, useStaticQuery} from 'gatsby';
import React from "react";
import css from './saas2.module.scss';
import {getImagesFluid, renderImageByFileName} from '../../componentsUtils';
import Text from "../../shared/Text/Text";
import {SectionDesktopTitle, SectionMobileTitle} from "../../shared/Section/SectionTitle";
import SectionImageVideoWrapper from "../../shared/Section/SectionImageVideoWrapper";
import SectionTextWrapper from "../../shared/Section/SectionTextWrapper";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_sections_saas2"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 424, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const Saas2 = () => {
    const imagesFluid = getImagesFluid(useStaticQuery(query));

    const title = 'txt';

    return (
        <div className={css.wrapper}>
            <SectionMobileTitle>{title}</SectionMobileTitle>
            <SectionImageVideoWrapper desktopWidth={'50%'}>
                <div className={css.imageVideoWrapper}>
                    <div className={css.imageWrapper}>
                        {renderImageByFileName(imagesFluid, 'SaaS_Web_App_2.png', 'SaaS Web App2')}
                    </div>
                </div>
            </SectionImageVideoWrapper>
            <SectionTextWrapper desktopWidth={'45%'}>
                <SectionDesktopTitle>{title}</SectionDesktopTitle>
                <Text>
                    Debitis voluptatem et et doloremque. We are helping our customers to make the digital
                    transformation move and automate processes for making better and faster decisions, for impacting
                    market competitiveness and financial standing, for protecting their brand value, for being a
                    part of the green environment.
                </Text>
            </SectionTextWrapper>
        </div>
    )
};

export default Saas2;
