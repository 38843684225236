import React from 'react';
import css from './breadcrumbs.module.scss';
import {getLink} from "../routes";
import {Link} from "gatsby";
import ArrowLeft from '../../../images/icons/arrow_small_left.svg';

const Breadcrumbs = props => {
    const {
        title = '',
        parentRoutes = [],
        parentTitles = [],
        sectionAnchors = []
    } = props;

    return (
        <div className={css.wrapper}>
            {parentTitles.length > 0 && parentRoutes.length > 0 && (
                <>
                    {parentRoutes.map((parentRoute, index) => (
                        <div className={css.parentRow} key={`parentRow_${index}`}>
                            <div className={css.parentTitle} key={`parentTitle_${index}`}>
                                <Link
                                    to={!!sectionAnchors ? `${getLink([parentRoute])}#${sectionAnchors[index]}` : getLink([parentRoute])}>
                                    <div className={css.linkContent} key={`linkContent${index}`}>
                                        {index === 0 && <img src={ArrowLeft} alt="" className={css.arrow}/>}
                                        {index > 0 && <div className={css.separator} key={`separator_${index}`}/>}
                                        {parentTitles[index]}
                                    </div>
                                </Link>
                            </div>
                        </div>
                    ))}
                    <div className={css.separator}/>
                </>
            )}
            <div className={css.title}>{title}</div>
        </div>
    );
};

export default Breadcrumbs;
