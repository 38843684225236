import {useCallback, useRef, useState} from "react";

//https://github.com/Aminadav/react-useStateRef/blob/master/index.ts

export default function useStateRef(initialState) {

    const [state, setState] = useState(initialState);
    const ref = useRef(state);

    const isFunction = (setStateAction) => {
        return typeof setStateAction === "function";
    }

    const dispatch = useCallback((setStateAction) => {
        ref.current = isFunction(setStateAction) ? setStateAction(ref.current) : setStateAction;
        setState(ref.current);
    }, []);

    return [state, dispatch, ref];
}