import React from 'react';
import Layout from '../../components/shared/Layout';
import SEO from '../../components/shared/SEO';
import RedirectTo from "../../components/shared/RedirectTo/RedirectTo";
import Saas1 from "../../components/sections/Saas1/Saas1";
import Saas2 from "../../components/sections/Saas2/Saas2";
import BackToSolutionsButton from "../../components/shared/ButtonBackTo/BackToSolutionsButton";
import AdditionalInformation
    from "../../components/shared/additionalInformation/additionalInformation";
import Footer from "../../components/shared/Footer/Footer";
import {getLink, getRoutes} from "../../components/shared/routes";
import Breadcrumbs from "../../components/shared/Breadcrumbs/Breadcrumbs";

export default () => (
    <RedirectTo url={getLink([getRoutes().Technology, getRoutes().Technology.sections.saas.anchor], true)}/>);


// const Saas = () => {
//     const routes = getRoutes();
// const parentTitle = routes.Solutions.pageTitle;

// return (
//     <Layout>
//         <SEO title={parentTitle}/>
//
//         <Breadcrumbs
//             title={routes.SolutionsSaaS.pageTitle}
//             parentTitles={[parentTitle]}
//             parentRoutes={[routes.Solutions]}
//         />
//
//         <Saas1/>
//
//         <Saas2/>
//
//         <BackToSolutionsButton/>
//
//         <AdditionalInformation/>
//
//         <Footer/>
//     </Layout>
// );
// };

// export default Saas;
